/* design system viewport margins */
import { FunctionComponent } from 'react'
import styled from 'styled-components'

interface IFlexProps {
  center?: boolean
}
const FlexContainer = styled.div<IFlexProps>`
  display: flex;
  justify-content: center;
  ${props =>
    props.center
    && `
    align-items: center;
  `}
  width: 100%;
  height: 100%;
`

export const MarginContainer = styled.div`
  width: 100%;
  margin: 0 ${props => props.theme.spacings[32]}px;

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    margin: 0 ${props => props.theme.spacings[48]}px;
  }

  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    max-width: ${props => props.theme.metrics.contentMaxWidth}px;
  }

  @media (min-width: ${props => props.theme.metrics.desktopLarge}px) {
    margin: 0 168px; /* @NOTE: this number may need to declared in design-system spacings */
  }
`

interface IProps {
  center?: boolean
  ref?: (node?: Element | null | undefined) => void
}
const ViewportMarginContainer: FunctionComponent<IProps> = ({ ref, center, children }) => (
  <FlexContainer
    ref={ref}
    center={center}
  >
    <MarginContainer>{children}</MarginContainer>
  </FlexContainer>
)

export default ViewportMarginContainer
