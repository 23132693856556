export const HowItWorksDataHomePage = [
  {
    title: 'Get It Listed',
    imgUrl: 'amazon/assets/hp-b/home-listed-yard-sign-front.jpg',
    details: [
      'Create your free listing',
      'Add home details, a description, upload photos, set a price',
      "We'll publish your listing to the MLS and real estate search sites (Zillow, Redfin, etc)",
    ],
  },
  {
    title: 'Show It',
    imgUrl: 'amazon/assets/hp-b/living-room-wooden-gray-furnitures.jpg',
    details: [
      'Get a professional yard sign',
      'Stage your home',
      'Host individual showings, open houses and virtual tours',
    ],
  },
  {
    title: 'Sell It and Save',
    imgUrl: 'amazon/assets/hp-b/happy-couple-home-sold.jpg',
    details: [
      'Receive and review offers from buyers',
      'Complete the closing paperwork with the closing agent',
      'Save an average of $21,000!',
    ],
  },
]

export const HowItWorksData = [
  {
    title: 'Get Your Home Listed',
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto,w_720/amazon/assets/how-it-works/how-it-works-step-1.jpg`,
    details: [
      'Create your listing and disclosures',
      'Stage your home and take photos',
      'Select the plan and level of support that makes the most sense for you',
      'We publish your listing to MLS and real estate sites (Zillow, Redfin, etc)',
    ],
  },
  {
    title: 'Market Your Home',
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto,w_720/amazon/assets/hp-b/home-listed-yard-sign-front.jpg`,
    details: [
      'We install a HomeLister yard sign',
      'Market locally to buyers',
      'Your listing will appear on real estate sites like Zillow, Redfin, etc',
      'Host open houses and virtual home tours',
    ],
  },
  {
    title: 'Offer Review + Escrow',
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto,w_720/amazon/assets/how-it-works/how-it-works-step-3.jpg`,
    details: [
      'Get offers',
      'Review offers with HomeLister team if you select Premium or Platinum plan',
      'Select the best offer',
      'Open escrow',
    ],
  },
  {
    title: 'Close',
    imgUrl: `${process.env.CDN_BASE_URL}/q_auto,w_720/amazon/assets/how-it-works/how-it-works-step-4.jpg`,
    details: [
      'You and the buyer will digitally sign all documents',
      'Complete the closing paperwork',
      'Save an average of $21,000 on commissions!',
      'Do something fun with your savings',
    ],
  },
]
