/* eslint-disable react/no-unused-prop-types */
import Link, { LinkProps } from 'next/link'
import { FunctionComponent, MouseEventHandler } from 'react'
import styled from 'styled-components'
import { ColorName } from 'themes/colors'
import { ZIndices } from 'themes/zIndices'

import Anchor from 'components/GlobalStyles/Anchor'

import { Either } from 'types'

import { ButtonSize, ButtonType } from './types'

interface IButtonContainerProps {
  center?: boolean
  fullWidth?: boolean
  zIndex?: ZIndices
}

const ButtonContainer = styled.div<IButtonContainerProps>`
  ${props => props.fullWidth && 'width: 100%;'}
  ${props =>
    props.center
    && `
    width: 100%;
    display: flex;
    justify-content: center;
  `}
  ${props => props.zIndex && `z-index: ${props.theme.zIndices[props.zIndex]};`}
`

interface IButtonTextProp {
  hasLeftIcon?: boolean
  hasRightIcon?: boolean
}

const ButtonText = styled.span<IButtonTextProp>`
  ${props => props.hasLeftIcon && 'padding-left: 12px;'}
  ${props => props.hasRightIcon && 'padding-right: 12px;'}
`

interface IStyledButtonProps {
  size: 'small' | 'medium' | 'large'
  backgroundColor?: ColorName
  disabled?: boolean
  fullWidth?: boolean
  boxShadow?: string
}

const PrimaryButton = styled.button<IStyledButtonProps>`
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  font-weight: ${props => props.theme.fonts.weights.heavy};
  text-align: center;
  text-transform: uppercase;
  color: ${props => props.theme.colors.white};
  letter-spacing: 1.5px;
  background-color: ${props =>
    props.backgroundColor ? props.theme.colors[props.backgroundColor] : props.theme.colors.blue500};
  border: none;
  border-radius: ${props => props.theme.radii[50]}px;
  z-index: inherit;
  ${props => props.boxShadow && `box-shadow: ${props.boxShadow};`}
  ${props =>
    !props.fullWidth && 'min-width: 230px;'} /* primary and secondary buttons have minimum width */
  ${props => props.fullWidth && 'width: 100%;'}

  ${props =>
    props.disabled
    && `
    color: ${props.theme.colors.neutral50};
    background-color: ${props.theme.colors.neutral200};
  `}

  &:hover, &:focus {
    background-color: ${props => props.theme.colors.blue400};

    ${props =>
    props.disabled
      && `
      color: ${props.theme.colors.neutral50};
      background-color: ${props.theme.colors.neutral200};
    `}
  }

  ${props =>
    props.size === 'small'
    && `
    height: 36px;
    padding: 0 32px;
    font-size: ${props.theme.fonts.sizes[12]}px;
  `}

  ${props =>
    props.size === 'medium'
    && `
    height: 44px;
    padding: 0 40px;
    font-size: ${props.theme.fonts.sizes[12]}px;
  `}

  ${props =>
    props.size === 'large'
    && `
    height: 52px;
    padding: 0 64px;
    font-size: ${props.theme.fonts.sizes[14]}px;
  `}
`

const SecondaryButton = styled(PrimaryButton)`
  color: ${props => props.theme.colors.blue500};
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.blue500};

  ${props =>
    props.disabled
    && `
    color: ${props.theme.colors.neutral200};
    border: 1px solid ${props.theme.colors.neutral200};
  `}

  &:hover, &:focus {
    color: ${props => props.theme.colors.blue400};
    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.blue400};

    ${props =>
    props.disabled
      && `
      color: ${props.theme.colors.neutral200};
      border: 1px solid ${props.theme.colors.neutral200};
    `}
  }
`

const TertiaryButton = styled(PrimaryButton)`
  color: ${props => props.theme.colors.blue500};
  background: none;
  min-width: auto;

  ${props => props.disabled && `color: ${props.theme.colors.neutral200};`}

  &:hover, &:focus {
    color: ${props => props.theme.colors.blue400};
    background: none;

    ${props => props.disabled && `color: ${props.theme.colors.neutral200};`}
  }
`

type IButtonBaseProps = {
  type: ButtonType
  size: ButtonSize
  label?: string
  loading?: boolean // @TODO: button loading state needs to be implemented in the design-system
  disabled?: boolean
  center?: boolean
  backgroundColor?: ColorName
  fullWidth?: boolean
  dataTestId?: string
  leftIcon?: JSX.Element
  rightIcon?: JSX.Element
  zIndex?: ZIndices
  boxShadow?: string
  value?: string | number | readonly string[] | undefined
  // link/action props
}

type ILink = {
  href?: LinkProps['href']
  as?: LinkProps['as']
  externalLink?: boolean
}

type IClickAction = {
  onClick?: () => void | MouseEventHandler<HTMLButtonElement> | undefined
}

type IButtonProps = IButtonBaseProps & Either<ILink, IClickAction>

export const StyledButton: FunctionComponent<IButtonProps> = props => {
  const styledButtonProps = {
    backgroundColor: props.backgroundColor,
    size: props.size || 'medium',
    fullWidth: props.fullWidth,
    disabled: props.disabled,
    boxShadow: props.boxShadow,
  }

  // primary by default
  let Button = PrimaryButton

  if (props.type === 'secondary') Button = SecondaryButton

  if (props.type === 'tertiary') Button = TertiaryButton

  return (
    <Button
      {...styledButtonProps}
      value={props.value}
      onClick={props.onClick}
    >
      {!!props.leftIcon && props.leftIcon}
      <ButtonText
        hasLeftIcon={!!props.leftIcon}
        hasRightIcon={!!props.rightIcon}
      >
        {props.label || 'Click'}
      </ButtonText>
      {!!props.rightIcon && props.rightIcon}
    </Button>
  )
}

const StandardButton: FunctionComponent<IButtonProps> = ({
  href,
  as,
  externalLink,
  dataTestId,
  ...props
}) => {
  const { label } = props

  if (href) {
    return (
      <ButtonContainer
        center={props.center}
        fullWidth={props.fullWidth}
        zIndex={props.zIndex}
      >
        <Link
          href={href || '/'}
          as={as}
          passHref
        >
          <Anchor
            data-testid={dataTestId || label}
            target={externalLink ? '_blank' : '_self'}
          >
            <StyledButton {...props} />
          </Anchor>
        </Link>
      </ButtonContainer>
    )
  }

  return (
    <ButtonContainer
      center={props.center}
      fullWidth={props.fullWidth}
      zIndex={props.zIndex}
    >
      <StyledButton
        {...props}
        data-testId={dataTestId}
      />
    </ButtonContainer>
  )
}

export default StandardButton
