import SVG from 'components/SVG/SVG'
import * as T from 'components/SVG/types'

const Checkmark = (props: T.ISVGProps) => (
  <SVG
    {...props}
    viewBox="0 0 13 11"
    d="M12.0606 0.20724C11.7787 -0.06908 11.3275 -0.06908 11.0456 0.20724L4.17282 7.08002L1.99789 4.91959C1.86633 4.77941 1.68265 4.6999 1.49041 4.6999C1.29816 4.6999 1.11448 4.77941 0.982923 4.91959L0.214447 5.68807C0.0771997 5.82419 0 6.0095 0 6.2028C0 6.39611 0.0771997 6.58141 0.214447 6.71754L3.62184 10.1539C3.7534 10.2941 3.93708 10.3736 4.12932 10.3736C4.32157 10.3736 4.50525 10.2941 4.63681 10.1539L12.7856 2.00518C12.9228 1.86906 13 1.68376 13 1.49045C13 1.29714 12.9228 1.11184 12.7856 0.975716L12.0606 0.20724Z"
  />
)

export const CheckmarkThin = (props: T.ISVGProps) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 10 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.17794 5.52274L0.807277 3.34601L0 4.08203L3.17794 7L10 0.736018L9.19841 0L3.17794 5.52274Z"
      fill={props.fill}
    />
  </svg>
)

export default Checkmark
