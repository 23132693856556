import styled from 'styled-components'

/*
  @NOTE:
  BackgroundImage component can be used for rendering images within the flexable width and height container.
  Create a wrapper container and define width and height on the parent element.
  If the wrapper container's ratio is different from given image ratio,
  the image will crop (not actually a crop of an image) to fit its parent width and height.
  border-radius css property can be set on the parent element and it will be inherited to the BackgroundImage.
*/
interface IBackgroundImageProps {
  backgroundSize: 'cover' | 'contain' // required
  imgSrc: string
  imgSrcTablet?: string
  imgSrcDesktop?: string
  backgroundPosition?: string
  backgroundGradient?: string // background gradient can be applied on top of the image src
  borderRadius?: number
}

const BackgroundImage = styled.div<IBackgroundImageProps>`
  display: block;
  width: 100%;
  height: 100%;
  background-image: ${props => props.backgroundGradient && `${props.backgroundGradient},`}
    url('${props => props.imgSrc}'); /* '' needed if image url includes parentheses */
  background-position: ${props => props.backgroundPosition || 'center'};
  background-size: ${props => props.backgroundSize || 'auto'};
  background-repeat: no-repeat;
  border-radius: ${props => (props.borderRadius ? `${props.borderRadius}px` : 'inherit')};

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    background-image: ${props => props.backgroundGradient && `${props.backgroundGradient},`}
      url(${props => props.imgSrcTablet || props.imgSrc});
  }
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    background-image: ${props => props.backgroundGradient && `${props.backgroundGradient},`}
      url(${props => props.imgSrcDesktop || props.imgSrc});
  }
`

export default BackgroundImage
