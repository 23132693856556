import styled from 'styled-components'

interface IGridProps {
  gridColumns?: string
  gridColumnsMobile?: string
  gridColumnsTablet?: string
  gridColumnsDesktop?: string
  gridRows?: string
  columnGap?: string
  columnGapMobile?: string
  columnGapTablet?: string
  columnGapDesktop?: string
  rowGap?: string
  rowGapTablet?: string
  rowGapDesktop?: string
}

export const Grid = styled.div<IGridProps>`
  display: grid;
  grid-template-columns: ${props =>
    props.gridColumnsMobile || props.gridColumns || 'repeat(4, 1fr)'};
  grid-template-rows: ${props => props.gridRows || 'none'};
  grid-column-gap: ${props => props.columnGapMobile || props.columnGap || '8px'};
  grid-row-gap: ${props => props.rowGap || '8px'};

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    grid-template-columns: ${props =>
    props.gridColumnsTablet || props.gridColumns || 'repeat(8, 1fr)'};
    grid-column-gap: ${props => props.columnGapTablet || props.columnGap || '20px'};
    grid-row-gap: ${props => props.rowGapTablet || props.rowGap || '20px'};
  }

  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    grid-template-columns: ${props =>
    props.gridColumnsDesktop || props.gridColumns || 'repeat(12, 1fr)'};
    grid-column-gap: ${props => props.columnGapDesktop || props.columnGap || '20px'};
    grid-row-gap: ${props => props.rowGapDesktop || props.rowGap || '20px'};
  }
`

interface IGridItemProps {
  gridRow?: string
  gridRowTablet?: string
  gridRowDesktop?: string
  gridColumn?: string
  gridColumnTablet?: string
  gridColumnDesktop?: string
}

export const GridItem = styled.div<IGridItemProps>`
  grid-column: ${props => (props.gridColumn ? props.gridColumn : 'auto')};
  grid-row: ${props => (props.gridRow ? props.gridRow : 'auto')};
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    grid-column: ${props => (props.gridColumnTablet ? props.gridColumnTablet : 'auto')};
    grid-row: ${props => (props.gridRowTablet ? props.gridRowTablet : 'auto')};
  }
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    grid-column: ${props => (props.gridColumnDesktop ? props.gridColumnDesktop : 'auto')};
    grid-row: ${props => (props.gridRowDesktop ? props.gridRowDesktop : 'auto')};
  }
`
